// enums
export enum ContractType {
    Original = '0',
    License = '1',
    Derivative = '2',
}

export enum TokenTypes {
    ERC1155 = 'ERC1155',
    ERC721 = 'ERC721',
}

export interface NFT {
    isFinish: any;
    image_url: string | undefined;
    balanceOf: number;
    address: string;
    tokenId: string;
    token_id: string;
    createdAt: string;
    asset_contract: any;
    image: string;
    name: string;
    sellerFeeBasisPoints: number;
    symbol: string;
    tokenURI: string;
    updatedAt: string;
    description: string;
    collection: Collection;
    erc721: boolean;
    erc1155: boolean;
    currentBalance: number;
    authorEarning?: string;
    lastSale?: string;
    collectionOriginal: any;
    last_sale?: {
        total_price: string;
    };
    supplyLimit: string;
    expiredAt?: string;
    leftTime?: number;
    last_lase: string;
    earnPoint: string;
    creator: {
        user: string;
        profile_img_url: string;
        address: string;
        config: string;
    };
    owner: {
        user: string;
        profile_img_url: string;
        address: string;
        config: string;
    };
    top_ownerships: {
        owner: {
            user: {
                username: string;
            };
            profile_img_url: string;
            address: string;
            config: string;
        };
        quantity: string;
        created_date: string;
    }[];
}
export interface Collection {
    contractAddress?: string;
    contractType?: ContractType;
    meta: {
        primary_asset_contracts: {
            address: string;
            asset_contract_type: string;
            created_date: string;
            name: string;
            nft_version: string;
            opensea_version: null;
            owner: null;
            schema_name: string;
            symbol: string;
            total_supply: null;
            description: string;
            external_link: string;
            image_url: string;
            default_to_fiat: boolean;
            dev_buyer_fee_basis_points: number;
            dev_seller_fee_basis_points: number;
            only_proxied_transfers: boolean;
            opensea_buyer_fee_basis_points: number;
            opensea_seller_fee_basis_points: number;
            buyer_fee_basis_points: number;
            seller_fee_basis_points: number;
            payout_address: null;
        }[];
        stats: {
            one_day_volume: number;
            one_day_change: number;
            one_day_sales: number;
            one_day_average_price: number;
            one_day_difference: number;
            seven_day_volume: number;
            seven_day_change: number;
            seven_day_sales: number;
            seven_day_average_price: number;
            seven_day_difference: number;
            thirty_day_volume: number;
            thirty_day_change: number;
            thirty_day_sales: number;
            thirty_day_average_price: number;
            thirty_day_difference: number;
            total_volume: number;
            total_sales: number;
            total_supply: number;
            count: number;
            num_owners: number;
            average_price: number;
            num_reports: number;
            market_cap: number;
            floor_price: number;
        };
        banner_image_url: string;
        chat_url?: string;
        created_date: string;
        default_to_fiat: boolean;
        description: string;
        dev_buyer_fee_basis_points: string;
        dev_seller_fee_basis_points: string;
        discord_url: string;
        display_data: {
            card_display_style: string;
        };
        external_url: string;
        featured: boolean;
        featured_image_url: string;
        hidden: boolean;
        safelist_request_status: string;
        image_url: string;
        is_subject_to_whitelist: boolean;
        large_image_url: string;
        medium_username: string;
        name: string;
        only_proxied_transfers: boolean;
        opensea_buyer_fee_basis_points: string;
        opensea_seller_fee_basis_points: string;
        payout_address: string;
        require_email: boolean;
        short_description: string;
        slug: string;
        telegram_url: string;
        twitter_username: string;
        instagram_username: string;
        wiki_url: string;
        is_nsfw: boolean;
        fees: {
            seller_fees: {
                '0x11b0d9afd49dc36d86dea579d0d11771a4f2f54b': number;
            };
            opensea_fees: {
                '0x0000a26b00c1f0df003000390027140000faa719': number;
            };
        };
        owned_asset_count: number;
    };
    createdAt?: string;
    updatedAt?: string;
}

export interface CollectionsParams {
    _?: string;
}

export interface Recommendation {
    derivative: string;
    license: string;
}
export interface NFTsCollectionQueryParams {
    contractAddress: string;
    userWalletAddress: string;
}

export interface NFTDetailParams {
    contractAddress: string;
    tokenId: string;
}
