import React from 'react';
import EmptyItem from '@/components/profile/components/emptyItem';

// it is to use this in list

export interface IListTemplateProps {
    children: React.ReactNode;
    empty: boolean;
    loading: boolean;
    renderLoading?: () => React.ReactNode;
}
function Index({ empty, children, loading, renderLoading }: IListTemplateProps) {
    return (
        <>
            {/* loading State */}
            {loading ? renderLoading ? renderLoading() : <div>loading..</div> : empty ? <EmptyItem /> : children}
        </>
    );
}
export default Index;
