import styles from './style.module.less';
import no_item from '@/assets/svgs/profile/no_item.svg';

export interface EmptyItemProps {
    text?: string;
}
const EmptyItem = ({ text = 'No items to display' }: EmptyItemProps) => {
    return (
        <div className={styles['empty-item']}>
            <div className={styles['logo']}>
                <img src={no_item} alt="" />
            </div>
            <div className={styles['word']}>{text}</div>
        </div>
    );
};

export default EmptyItem;
